<template>
  <v-card flat height="100%" class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <announcement-table
        :items="announcements"
        :total="totalItems"
        @update="updateTable"
      ></announcement-table>
      <!--      <v-tabs height="30">-->
      <!--        <v-tab class="font-weight-bold text-color-main">-->
      <!--          Recurring-->
      <!--        </v-tab>-->
      <!--        <v-tab class="font-weight-bold text-color-main">-->
      <!--          Past-->
      <!--        </v-tab>-->
      <!--      </v-tabs>-->
    </v-card-text>
  </v-card>
</template>

<script>
import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const announcementRepository = RepositoryFactory.get("announcement");
const AnnouncementTable = () => import("../table/AnnouncementTable.vue");

export default {
  components: {
    AnnouncementTable
  },
  data: () => ({
    title: "Announcements",
    announcements: [],
    params: {
      page: 1,
      per_page: 15
    },
    totalItems: 0,
    eventName: "reloadItems_announcements", //muse be same as the eventname on ContentViewer.vue
  }),
  methods: {
    updateTable(event) {
      this.params = event;
      this.getAnnouncements();
      // this.announcements = [];
    },
    async getAnnouncements() {
      let params = { ...this.params };
      await announcementRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;
          this.totalItems = items.total;

          this.announcements = items.data.map(item => {
            let { id, title, body } = item;

            return {
              id,
              title,
              content: body
            };
          });
        })
        .catch(() => {
          this.announcements = [];
        });
    },
    reloadItemsDeleteEventBus() {
      DELETE_ITEM_EVENT_BUS.$off(this.eventName);
      DELETE_ITEM_EVENT_BUS.$on(this.eventName, () => {
        // this.getData();
        this.getAnnouncements();
      });
    },
    setupEventBus() {
      this.reloadItemsDeleteEventBus();
    }
  },
  mounted() {
    this.setupEventBus();
  }
};
</script>
